/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.bg-gsi {
    background-color: #5390D6 !important;
}

.gsi-blue {
    color: #5390D6 !important;
}

.bg-white {
    background-color: white;
}

body {
    background-color: #E9EBEE;
    font-family: calibri, sans-serif !important;
    font-size: 90%;
    /*overflow-y: scroll;*/
}

.jumbotron {
    background-color: white;
}

.commentText {
    background-color: #E9EBEE;
    border-radius: 20px;
}

.BorderBottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.modal-open {
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 0 !important;
}

.underline {
    text-decoration: underline
}

.small-caps {
    font-size: 70%;
    text-transform: uppercase;
}