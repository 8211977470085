.Dropzone {
	height: 200px;
	width: 400px;
	background-color: #fff;
	border: 2px dashed rgb(187, 186, 186);
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 16px;
	margin: 10px auto;
}

.Highlight {
	background-color: #A3CEFF;
}

.Icon {
	opacity: 0.3;
	height: 64px;
	width: 64px;
}

.FileInput {
	display: none;
}
