
.ReplyToLabel:hover {
	color: #dc3545 !important;
}

.highlight {
	box-shadow: 0px 0px 10px 0px #5390D6;
}


.replyLink:hover {
	color: #5390D6 !important;
}

.carousel {
	min-height: inherit;
}

.carousel-inner {
	min-height: inherit;
}

.carousel-item {
	min-height: inherit;
}

.carousel-item > img {
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;
	width: unset !important;
	object-fit: scale-down;
	min-height: inherit;
}

.tabSelect {
	background-color: rgb(233, 235, 238);
}

.responseTab:hover {
	box-shadow: 0px 0px 10px 0px rgb(233, 235, 238);
}
.responseLink {
	text-decoration: underline;
	color: #6666ff;
	cursor: pointer;
}

.responseLink:hover {
	color: #b3b3ff;
}

.rawHtml h1 {
	font-size: 1.25rem;
	margin-bottom: 0px;
}

.rawHtml h2 {
	font-size: 1.10rem;
	margin-bottom: 0px;
}

#ReactSimpleImageViewer {
	z-index: 1050;
}