body {
}

.PopoutTagAdd:hover {
	color: #dc3545 !important;
}

#NewPostText1 h1 {
	font-size: 1.25rem;
}

#NewPostText1 h2 {
	font-size: 1.10rem;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
	font-size: 1.25em;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
	font-size: 1.10em;
}