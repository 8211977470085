.profileData {
	font-weight: normal;
}

.profileData:before {
	content: "\00a0 ";
}

.profileData:after {
	content: "\00a0 ";
}

.notif_pref p {
	margin: 0px;
}

.alt-row {
	background-color: rgba(0, 0, 0, 0.05);
}