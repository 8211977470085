.ReactionAdd:hover {
	/*text-shadow: 0px 0px 10px #5390D6;*/
	-webkit-filter: drop-shadow(0px 0px 10px #5390D6);
	filter: drop-shadow(0px 0px 10px #5390D6);
}

.SelectedReaction {
	/*text-shadow: 0px 0px 10px #333;*/
	-webkit-filter: drop-shadow(0px 0px 10px #333);
	filter: drop-shadow(0px 0px 10px #333);
}

.AllReactionDropdown {
	width: 250px !important;
}