.wide {
	width: 30%;

}

.medium {
	width: 15%;
}

.regular {
}
