
.notification {
	color: black;
	text-decoration: none;
}

.notification:hover {
	color: black;
	text-decoration: none;
}

.notification-read {
	background-color: white !important;
}

.notification-unread {
	background-color: #add8e64d !important;
}
